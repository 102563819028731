"use client";
import styled, { keyframes } from "styled-components";

export const Opening = styled.div`
  border-radius: 56px;
  background: var(--background-accent-secondary, #defef9);
  padding: var(--spacing-space-48, 48px);
  gap: var(--spacing-space-64, 64px);
  position: relative;
  isolation: isolate;
  overflow: hidden;
  .fullWidth {
    max-width: 440px;
  }
  @media (max-width: 768px) {
    border-radius: var(--rounding-radius-xxl, 32px);
    padding: var(--spacing-space-32, 32px) var(--spacing-space-24, 24px)
      var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
    .fullWidth {
      max-width: 100%;
    }
  }
  .btn-bootstrap {
    padding: 0 4px;
  }
`;

export const OpeningContent = styled.div`
  max-width: 440px;
`;

export const OpeningPicture = styled.img`
  position: absolute;
  bottom: 144px;
  right: 80px;
  height: 584px;
  object-fit: contain;
  object-position: right bottom;
  z-index: -1;
  &.mobile {
    display: none;
  }
  @media (max-width: 768px) {
    width: 600px;
    height: 412px;
    top: 68px;
    left: 168px;
    bottom: auto;
    right: auto;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
  @media (max-width: 390px) {
    width: 390px;
    height: 268px;
    object-fit: center;
    left: 0;
    top: 152px;
  }
`;

export const OpeningTitle = styled.h1`
  margin: 0;
  font-size: 64px;
  line-height: 80px;
  font-variation-settings: "wght" 700;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const textSwipes = keyframes`
    0% {
        -webkit-transform: translate(00%, 0);
        transform: translate(0%, 0);
    }
    25% {
        -webkit-transform: translate(0, -25%);
        transform: translate(0, -25%);
    }
    30% {
        -webkit-transform: translate(0, -25%);
        transform: translate(0, -25%);
    }
    50% {
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
    55% {
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
    75% {
        -webkit-transform: translate(0, -75%);
        transform: translate(0, -75%);
    }
    80% {
        -webkit-transform: translate(0, -75%);
        transform: translate(0, -75%);
    }
    100% {
        -webkit-transform: translate(00%, 0);
        transform: translate(0%, 0);
    }
`;

export const AnimatedWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 80px;
  @media (max-width: 768px) {
    height: 56px;
  }
`;

export const AnimatedText = styled.div`
  color: var(--text-primary, #000);
  font-size: 64px;
  font-weight: 400;
  font-variation-settings: "wght" 700;
  line-height: 80px;
  & > div {
    height: 80px;
    transform: translateY(-6px);
  }
  .anything {
    color: #5cdfd4;
    font-style: italic;
  }

  animation: ${textSwipes} 9s infinite cubic-bezier(0.79, 0, 0.2, 1);
  animation-fill-mode: both;

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 50px;
    & > div {
      height: 58px;
    }
  }
`;

export const Report = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--fill-accent-secondary, rgba(50, 250, 220, 0.16));
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  display: flex;
  padding: var(--spacing-space-16, 16px) var(--spacing-space-32, 32px)
    var(--spacing-space-16, 16px) var(--spacing-space-16, 16px);
  align-items: center;
  gap: var(--spacing-space-16, 16px);
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    padding: var(--spacing-space-32, 32px);
    text-align: center;
  }
`;

export const ReportIcon = styled.div`
  position: relative;
  border-radius: 50%;
  background: var(--fill-elevated, #fff);
  display: flex;
  padding: var(--spacing-space-16, 16px);
  justify-content: center;
  align-items: center;
  .checkmark {
    height: 24px;
    object-fit: contain;
    position: absolute;
    top: -2px;
    right: -2px;
  }
  .qstamp {
    width: 32px;
    height: 32px;
  }
`;

export const ReportButtons = styled.div`
  margin-left: auto;
  gap: 8px;
  display: flex;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const GrayBlock = styled.div`
  border-radius: 56px;
  background: var(--background-secondary, #f7f4f3);
  padding: var(--spacing-space-48, 48px);
  .mobile-btn {
    display: none;
  }
  @media (max-width: 768px) {
    border-radius: var(--rounding-radius-xxl, 32px);
    padding: 32px 24px;
    .mobile-btn {
      display: block;
    }
  }
`;

export const DoubleSparks = styled.div`
  display: grid;
  grid-template-columns: 395px 1fr 1fr;
  gap: var(--spacing-space-32, 32px);
  & > *:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .elevated {
      width: fit-content;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 24px;
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
`;

export const SparkCard = styled.div`
  position: relative;
  height: 400px;
  border-radius: var(--rounding-radius-xxl, 32px);
  background: #000;
  padding: var(--spacing-space-32, 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
  isolation: isolate;
  text-align: center;
  overflow: hidden;
  p {
    color: #faf8f7;
  }
  .pic {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 212px;
  }
  .headerM {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.32px;
  }
`;

export const SparkBar = styled.div`
  border-radius: var(--rounding-radius-m, 16px);
  background: rgba(204, 187, 184, 0.16);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  display: flex;
  height: 32px;
  padding: var(--spacing-space-4, 4px) var(--spacing-space-12, 12px)
    var(--spacing-space-4, 4px) var(--spacing-space-4, 4px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-space-4, 4px);
  color: #fff;
  &.accent {
    font-style: italic;
    color: #000;
    background: var(--Points, #c8ff26);
  }
  &.soon {
    font-style: normal;
    width: fit-content;
  }
`;

export const MarketsBlock = styled.div`
  @media (max-width: 1024px) {
    padding: 0 24px;
  }
`;

export const MarketsGridOverflow = styled.div`
  overflow: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scrollbar-color: rgba(255, 255, 255, 0);
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 1024px) {
    padding: 0 24px;
    margin: 0 -24px;
  }

  &::-webkit-scrollbar,
  &::-moz-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    -webkit-appearance: none;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar:horizontal {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
    opacity: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track-piece,
  &::-webkit-resizer {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    opacity: 0;
    background: rgba(255, 255, 255, 0);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
    background-color: transparent;
    width: 0 !important;
    height: 0 !important;
  }
`;

export const MarketsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
  max-width: 936px;
  @media (max-width: 1024px) {
    gap: 40px;
  }
`;

export const MarketUnit = styled.div`
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  @media (max-width: 1024px) {
    min-width: 280px;
  }
`;

export const ApyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    text-align: center;
    .text {
      display: none;
    }
  }
`;

export const Schema = styled.div`
  display: grid;
  grid-template-columns: 1fr 328px 1fr;
  gap: 12px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    & > * {
      width: 100%;
    }
  }
`;

export const SchemaBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 312px;

  @media (max-width: 1024px) {
    min-height: auto;
  }
`;

export const SchemaCard = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: var(--background-primary, #fff);
  padding: var(--spacing-space-16, 16px) var(--spacing-space-24, 24px)
    var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);

  &.inrow {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-space-16, 16px) var(--spacing-space-24, 24px)
      var(--spacing-space-24, 24px) var(--spacing-space-24, 24px);
    isolation: isolate;
    height: 100%;
    min-height: 312px;
    .colored > * {
      color: #33afa6;
    }
    .decor {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      height: 230px;
      object-fit: contain;
    }
    @media (max-width: 1024px) {
      min-height: 184px;
      height: auto;
    }
  }
`;

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TokensRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    width: 112px;
    object-fit: contain;
  }
`;

export const LeverageRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  & > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  .cross {
    width: 28px;
    height: 28px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const LeverageWrapper = styled.div`
  border-radius: 1000px;
  background: var(--fill-accent-secondary, rgba(50, 250, 220, 0.16));
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: var(--spacing-space-24, 24px);
`;

export const LeverageContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: var(--fill-accent-primary, #9fe);
  padding: 2px 2px 2px 12px;
  .logo {
    margin-right: 4px;
  }
  .action {
    font-variation-settings: "wght" 700;
  }
`;

export const LeverageValue = styled.div`
  border-radius: var(--rounding-radius-l, 22px);
  background: #fff;
  box-shadow: 0px 2px 1px 0px rgba(51, 20, 0, 0.04),
    0px 4px 8px 0px rgba(51, 20, 0, 0.08);
  padding: 11px 14px 13px 16px;
`;

export const CardsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const Referral = styled.div`
  border-radius: 56px;
  background: #defef9;
  padding: var(--spacing-space-48, 48px);
  position: relative;
  isolation: isolate;
  overflow: hidden;

  .decor {
    position: absolute;
    z-index: -1;
    width: 680px;
    height: 680px;
    object-fit: contain;
    object-position: right bottom;
    right: -87px;
    bottom: -236px;
  }
  @media (max-width: 768px) {
    border-radius: var(--rounding-radius-xxl, 32px);
    padding: var(--spacing-space-24, 24px);
    .decor {
      width: 412px;
      height: 412px;
      right: -52px;
      bottom: -150px;
    }

    .headerL {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

export const ReferralRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ReferalIcon = styled.div`
  border-radius: var(--rounding-radius-xl, 24px);
  background: #c8ff26;
  padding: var(--spacing-space-12, 12px);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 768px) {
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
`;

export const ReferInput = styled.input`
  border-radius: var(--rounding-radius-m, 16px);
  background: var(--fill-secondary, rgba(204, 187, 184, 0.16));
  padding: 0px var(--spacing-space-16, 16px);
  color: #000;
  display: block;
  flex-grow: 1;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blue(12px);
  height: 64px;
  border: 0;
  outline: 0;
`;
