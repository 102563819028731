"use client";
import Button from "@marginly/ui/components/button";
import T from "@marginly/ui/components/typography";
import cn from "classnames";
import NextLink from "next/link";
import React, { useEffect } from "react";

import { MAIN_APP_URL } from "@/constants/links";
import { trackLaunchAppClick, trackSessionStart } from "@/util/tracking";

import { Container } from "../container.styled";
// import CamelotBanner from "./ramses-banner/camelot-banner";
// import TraderjoeBanner from "./ramses-banner/traderjoe-banner";
// import { RamsesBanner } from "./ramses-banner";
import { AirdropButton, HeaderButtons, HeaderWrapper, Nav } from "./styled";

const Header = () => {
  const [scroll, setScroll] = React.useState(0);

  useEffect(() => {
    trackSessionStart();
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* <RamsesBanner className={cn({ scrolled: scroll > 0 }, "secondary")} /> */}
      {/* <TraderjoeBanner /> */}
      {/* <CamelotBanner /> */}
      <HeaderWrapper className={cn({ scrolled: scroll }, "nobanner")}>
        <Container>
          <Nav>
            <a href="/" className="homelink" rel="noreferrer noopener">
              <img src="/images/logo.svg" alt="" className="desktop-logo" />
              <img
                src="/images/logotype-new.png"
                alt=""
                className="mobile-logo"
              />
            </a>

            <HeaderButtons>
              <NextLink
                href="https://app.marginly.com/sparks"
                rel="noreferrer noopener"
              >
                <AirdropButton>
                  <img src="/images/spark-black.svg" alt="" />
                  <T action>Get Sparks</T>
                </AirdropButton>
              </NextLink>

              <NextLink
                href={MAIN_APP_URL}
                rel="noreferrer noopener"
                onClick={() => trackLaunchAppClick()}
              >
                <Button md text className="discord">
                  <T action style={{ color: "#000" }}>
                    Launch App
                  </T>
                </Button>
              </NextLink>
            </HeaderButtons>
          </Nav>
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default Header;
